import {
    Button, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    NumberInput,
    NumberInputField, Tooltip
} from "@chakra-ui/react";
import React from "react";
import axios from "axios";
import {useTranslate} from "@tolgee/react";
import Payment from '/components/payment'
import Api from "./Api";

export default function balanceAdd({ modal, setModal, user, desktop, moneyToMain, displayMoney }) {
    const t = useTranslate()
    const [data, setData] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const addBalance = () => {
        if(!data.amount || !data.pm || data.amount <= 0) return;
        setLoading(true);
        Api.post('/user/balance', { amount: moneyToMain(data.amount), paymentMethod: data.pm }).then(({data}) => {
            window.location.href = data.url;
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false)
        })
    }
    const setPM = (id) => {
        setData({...data, pm: id })
    }
    React.useEffect(() => {
        if(modal != true && Number(modal) > 0) setData({ amount: Number(modal), disabled: true })
    }, [modal])
    return (<Modal isOpen={modal} size={'3xl'} onClose={() => { setModal(false); setData(false); }}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>{t('t.balance.adding')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <NumberInput isReadOnly={data.disabled} value={data.disabled && `${displayMoney(moneyToMain(data.amount))}`}>
                    <NumberInputField defaultValue={data.amount} onChange={(e) => setData({ amount: Number(e.target.value) })} placeholder={t('app.amount')} variant={'filled'} />
                </NumberInput>
                <Payment setPM={setPM} pm={data.pm} user={user} amount={data.amount ? moneyToMain(data.amount) : 0} desktop={desktop} displayMoney={displayMoney} />
                <Tooltip shouldWrapChildren label={!data.amount ? t('app.balance.error.amount') : !data.pm && t('app.balance.error.pm')}>
                    <Button onClick={addBalance} isLoading={loading} isDisabled={!data.pm || !data.amount} my={5}>{t('app.balance.add')}</Button>
                </Tooltip>
            </ModalBody>
        </ModalContent>
    </Modal>)
}