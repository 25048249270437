import React from "react";
import {useTranslate} from "@tolgee/react";


export default function Status({ status }) {
    const t = useTranslate()
    return (<div className={'text-end'}>
        <div className={`fit-content ${status == 1 ? 'bg-green-100 dark:bg-green-200' : status == 2 ? 'bg-gray-100 dark:bg-gray-200' : status == 3 ? 'bg-red-100 dark:bg-red-200' : status == 4 && 'bg-yellow-100 dark:bg-yellow-200'} dark:text-gray-900 text-center rounded-full w-auto font-semibold inline-flex px-3 py-1 text-sm`}>
            <span className={`inline-block h-2 w-2 rounded-full ${status == 1 ? 'bg-green-400 dark:bg-green-500' : status == 2 ? 'bg-gray-400 dark:bg-gray-500' : status == 3 ? 'bg-red-400 dark:bg-red-500' : status == 4 && 'bg-yellow-400 dark:bg-yellow-500'} mr-2 mt-1.5`} />
            <p>{t(`app.products.statuses.${status}`)}</p>
        </div>
    </div>)
}