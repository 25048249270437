import {
    PopoverTrigger,
    Avatar,
    PopoverContent,
    Popover, PopoverCloseButton, useDisclosure
} from '@chakra-ui/react'
import {UserOutlined} from "@ant-design/icons";
import {FiChevronDown} from "react-icons/fi";
import Link from "next/link";
import React from "react";

export default function Dropdown({ children, items, disabled, className }) {
    const { onOpen, onClose, isOpen } = useDisclosure()
    return (<Popover isOpen={isOpen} onOpen={() => { if(!disabled) onOpen() }} onClose={onClose}>
        <PopoverTrigger>
            {children}
        </PopoverTrigger>
        <PopoverContent border={0} w={'auto'} shadow={'md'} className={'py-1'}>
            {items.map(item => (
                <div onClick={() => { item.click(); onClose(); }} className={`${item.danger ? 'text-red-500 hover:bg-red-500 hover:text-white dark:hover:bg-dark-700' : 'hover:bg-gray-200 dark:hover:bg-gray-700'} transition-background text-sm font-medium my-0 mx-1 px-2 py-1.5 cursor-pointer m-1 rounded-md ${className}`}>
                    {item.label}
                </div>
            ))}
        </PopoverContent>
    </Popover>)
}