import Head from 'next/head'
import {
    ColorModeScript,
    ChakraProvider,
    extendTheme,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Heading,
    Text,
    Alert,
    AlertIcon,
    Container,
    useColorMode,
} from '@chakra-ui/react'
import React from 'react';
import axios from "axios";
import Cookies from "js-cookie";
import Router, { useRouter } from "next/router";
import '/public/style.css'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/pl'
import 'moment/locale/uk'
import '@fontsource/manrope/200.css'
import '@fontsource/manrope/300.css'
import '@fontsource/manrope/400.css'
import '@fontsource/manrope/500.css'
import '@fontsource/manrope/600.css'
import '@fontsource/manrope/700.css'
import '@fontsource/manrope/800.css'
import UserNavbar from '/components/navbar/user'
import Loading from '/components/loading'
import BalanceAdd from '/components/balanceAdd'
import {TolgeeProvider} from "@tolgee/react";
import { ConfigProvider, theme, Button, Card } from "antd";
import dayjs from "dayjs"
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { Api } from '/components'

dayjs.extend(weekday)
dayjs.extend(localeData)

function My({ Component, pageProps }) {
    const { colorMode, toggleColorMode } = useColorMode()
    const [themeState, setTheme] = React.useState(typeof window !== 'undefined' ? localStorage.getItem('chakra-ui-color-mode') : false)
    const [user, setUser] = React.useState('loading')
    const [desktop, setDesktop] = React.useState(false)
    const [ws, setWs] = React.useState(false)
    const [support, setSupport] = React.useState(false)
    const [service, setService] = React.useState(false)
    const [balanceAdd, setBalanceAdd] = React.useState(false)
    const [versionDisplay, setVersionDisplay] = React.useState(false)
    const [advertisements, setAdvertisements] = React.useState([])
    const router = useRouter()
    const configTheme = extendTheme({
        fonts: {
            heading: `'Manrope', sans-serif`,
            body: `'Manrope', sans-serif`,
        },
    })
    const displayMoney = (amount, currency = null, symbol = true, balance = false) => {
        if(!currency && user) currency = user.interface.currency;
        let data = desktop.currencies.find(e => e.name == currency);
        if(!data) return;
        if(balance && desktop.currencies.find(e => e.main == 1).name == 'rub') {
            amount = decimalAdjust('floor', amount, 0)
        }
        return `${decimalAdjust('round', amount*data.value, data.multiplier)} ${symbol ? data.symbol : ''}`
    }
    const moneyToMain = (amount) => {
        let currency = user.interface.currency;
        let data = desktop.currencies.find(e => e.name == currency);
        let main = desktop.currencies.find(e => e.main == 1)
        if(!data || !main) return;
        return amount/data.value;
    }
    const unAuth = ['/login', '/register', '/registered', '/restore', '/backendError']
    if(user != 'loading' && !user && !unAuth.includes(router.route)) Router.push('/login')
    const webSocket = () => {
        if(unAuth.includes(router.pathname) || ws) return;
        const websock = new WebSocket(`${process.env.NEXT_PUBLIC_WS_URL}/ws/${Cookies.get('bc_token')}`);
        websock.onopen = function() {
            setWs(websock)
        };
        websock.onerror = function(err) {
            setWs(false)
        }
        websock.onclose = function(err) {
            setWs(false)
            setTimeout(webSocket, 1500);
        }
    }
    React.useEffect(() => {
        if(router.pathname == '/backendError') return;
        Api.get('/user').then(({data}) => {
            if(unAuth.includes(router.route) || router.route == '/') Router.push('/dashboard')
            let arr = [];
            data.projects.map(member => {
                let object = {
                    ...member.projectInfo,
                }
                delete(member.projectInfo)
                object.member = member
                arr.push(object)
            })
            setUser({...data.user, permissions: data.user.group, projects: arr })
            webSocket();
        }).catch(err => {
            console.log(err)
            if(!unAuth.includes(router.route)) Router.push('/login')
            setUser(false)
        })
        Api.get('/desktop').then(({data}) => {
            setDesktop(data)
        }).catch(err => {

        })
        Api.get(`/desktop/advertisements`).then(({data}) => {
            setAdvertisements(data.advertisements)
        }).catch(err => {

        })
        if(themeState == 'dark') {
            document.documentElement.classList.add('dark')
        }
    }, [])
    React.useEffect(() => {
        if(user.interface) moment.locale(getLanguage())
    }, [user])
    if(ws) ws.onmessage = function(msg) {
        if(msg.data.includes('ping')) return;
        let data = JSON.parse(msg.data);
        if(data.type == 'user_update') {
            setUser({...user, ...data.data })
        }
    }
    if(typeof window != 'undefined') document.addEventListener('keydown', (event) => {
        if(event.ctrlKey && event.which == 77 && !versionDisplay) {
            setVersionDisplay(true)
        }
    })
    const getLanguage = () => {
        if(typeof window === 'undefined' || !desktop) return 'en';
        let language;
        if(!user.interface) {
            var userLang = navigator.language || navigator.userLanguage;
            userLang = userLang.split('-')[0]
            if(desktop.languages.find(e => e.slug == userLang)) language = userLang;
            else language = 'en'
        } else language = user.interface.language;
        return language;
    }
    return (
        <ChakraProvider theme={configTheme} toastOptions={{ defaultOptions: { variant: 'subtle' }}}>
            <ColorModeScript initialColorMode={'light'} />
            <div style={{ minHeight: '100vh' }}>
                <Head>
                    <title>Berch Cloud Hosting</title>
                    <link rel="icon" href="/favicon.png" />
                </Head>
                <ConfigProvider theme={{
                    token: themeState == 'dark' && {
                        controlItemBgActive: '#475569',
                        colorBgBase: '#1f2937',

                    },
                    algorithm: themeState == 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm
                }}>
                    {desktop && user != 'loading' ? <TolgeeProvider forceLanguage={getLanguage()} apiKey={process.env.NEXT_PUBLIC_TOLGEE_API_KEY} apiUrl={process.env.NEXT_PUBLIC_TOLGEE_API_URL} staticData={{ [user.interface ? user.interface.language : getLanguage()]: require(`/locales/${user.interface ? user.interface.language : getLanguage()}.json`) }}>
                            <UserNavbar setUser={setUser} displayMoney={displayMoney} setBalanceAdd={setBalanceAdd} support={support} router={router} user={user}>
                                {advertisements.filter(e => (e.route.includes(router.route) || e.route.length == 0)).map(ad => (
                                    <Container maxW={'100%'} pt={2} px={8}>
                                        <Alert status={ad.type} mr={4} rounded={'md'}>
                                            {ad.icon ? <AlertIcon /> : ''}
                                            {ad.message[user.interface.language] ? ad.message[user.interface.language] : ad.message.ru}
                                        </Alert>
                                    </Container>
                                ))}
                                <Component {...pageProps} theme={themeState} setTheme={setTheme} balanceAdd={balanceAdd} setBalanceAdd={setBalanceAdd} service={service} setService={setService} moneyToMain={moneyToMain} ws={ws} support={support} user={user} setUser={setUser} desktop={desktop} displayMoney={displayMoney} />
                            </UserNavbar>
                            <BalanceAdd modal={balanceAdd} setModal={setBalanceAdd} user={user} desktop={desktop} moneyToMain={moneyToMain} displayMoney={displayMoney} />
                    </TolgeeProvider> : <Loading />}
                </ConfigProvider>
            </div>
        </ChakraProvider>
    )
}

function decimalAdjust(type, value, exp) {
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

export default My
