import {
    Skeleton, SimpleGrid, GridItem
} from '@chakra-ui/react'

export default function SkeletonCards({ children, number }) {
    let arr = [];
    for (let i = 0; i < number; i++) {
        arr.push('card')
    }
    return (<>
        <SimpleGrid columns={3} spacing={4}>
            {arr.map(m => (<GridItem>
                <Skeleton height={'100px'} />
            </GridItem>))}
        </SimpleGrid>
    </>)
}