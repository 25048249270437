import {Button, Heading, HStack, Skeleton, Text, Tooltip, Box, SimpleGrid, GridItem} from "@chakra-ui/react";
import {useTranslate} from "@tolgee/react";
import {Api} from ".";
import React from 'react'

export default function Payment({ user, desktop, displayMoney, amount, pm, setPM, type }) {
    const [pms, setPMs] = React.useState(false)
    const t = useTranslate()
    const categories = [
        {
            name: 'app.invoices.paying.bank_cards',
            category: 'cards',
        },
        {
            name: 'app.invoices.paying.ewallets',
            category: 'ewallets'
        },
        {
            name: 'app.invoices.paying.online_payments',
            category: 'opayments'
        }
    ]
    React.useEffect(() => {
        Api.get('/desktop/payment_methods').then(({data}) => {
            setPMs(data.methods)
        })
    }, [])
    if(pms) return (<Box my={5}>
        {type == 'payForm' && <div className={`${pm == 'balance' ? 'bg-gray-200 dark:bg-gray-600 border-blue-300' : 'bg-gray-100 dark:bg-gray-700 border-transparent'} p-3 rounded-lg ${user.balance < amount ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600'} border`} onClick={() => { user.balance >= amount && setPM('balance') }}>
            <p className={'text-md font-bold'}>{t(`app.invoices.paying.pms.2`)}</p>
        </div>}
        {categories.map(c => {
            if(pms.filter(e => e.category == c.category).length == 0) return;
            return (<>
                <Heading size={'md'} my={3} fontWeight={700}>{t(c.name)}</Heading>
                <SimpleGrid columns={type == 'payForm' ? 1 : 2} spacing={2}>
                    {pms.filter(e => e.category == c.category).map(p => {
                        return (<>
                            <GridItem>
                                <div className={`${pm == p.id ? 'bg-gray-200 dark:bg-gray-600 border-blue-300' : 'bg-gray-100 dark:bg-gray-700 border-transparent'} p-3 rounded-lg ${(!amount || Number(amount) < Number(p.min) ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600')} border`} onClick={() => { if(amount && Number(amount) >= Number(p.min)) setPM(p.id) }}>
                                    {type == 'payForm' ? <SimpleGrid columns={4}>
                                        <GridItem colSpan={3}>
                                            <img src={`${process.env.NEXT_PUBLIC_S3_URL}/payments/methods/${p.logo}`} style={{ maxHeight: 20 }} />
                                        </GridItem>
                                        <GridItem colSpan={1}>
                                            <p>{t('app.from')} <span className={'font-semibold'}>{displayMoney(p.min)}</span></p>
                                        </GridItem>
                                        <GridItem colSpan={3}>
                                            <p className={'text-md font-bold'}>{p.name[user.interface.language]}</p>
                                            <p>{t('app.invoices.gateway')} <span className={'font-semibold'}>{p.via}</span></p>
                                        </GridItem>
                                        <GridItem colSpan={1}>
                                            <p>{t('app.invoices.com')} <span className={'font-semibold'}>{p.com}</span></p>
                                        </GridItem>
                                    </SimpleGrid> : <>
                                        <img src={`${process.env.NEXT_PUBLIC_S3_URL}/payments/methods/${p.logo}`} style={{ maxHeight: 30 }} />
                                        <Heading my={2} size={'sm'}>{p.name[user.interface.language]}</Heading>
                                        <Text>{t('app.from')} <b>{displayMoney(p.min)}</b><br/>{t('app.invoices.com')} <b>{p.com}</b><br/>{t('app.invoices.gateway')} <b>{p.via}</b></Text>
                                    </>}
                                </div>
                            </GridItem>
                        </>)
                    })}
                </SimpleGrid>
            </>)
        })}
    </Box>)
}