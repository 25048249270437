import { Box, Heading, Spinner, useColorModeValue, Image } from '@chakra-ui/react'
import React from "react";

export default function loading(props) {
    return (<Box style={{ position: 'absolute', top: '50%', right: '50%', transform: 'translate(50%, -50%)', textAlign: 'center' }}>
        <div style={{justifyContent: 'center', display: 'flex'}}>
            <img className={'block dark:hidden'} src={'/logoNav.svg'} />
            <img className={'hidden dark:block'} src={'/logoNavDark.svg'} />
            {/*<Image src={'/logo.svg'} mb={5} boxSize={40} />*/}
        </div>
        <Spinner mt={10}/>
    </Box>)
}