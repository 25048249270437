import {
    PopoverTrigger,
    Avatar,
    PopoverContent,
    Popover, PopoverCloseButton, useDisclosure, HStack, Button, Divider
} from '@chakra-ui/react'
import {BsThreeDots, BsTrash} from "react-icons/bs";
import {ImFileEmpty} from "react-icons/im";
import Link from "next/link";
import {Checkbox, Pagination} from 'antd'
import React from "react";
import Dropdown from '/components/ui/dropdown'
import {useTranslate} from "@tolgee/react";

export default function table({ source, columns, settings, displayNumber, className, double, buttons, disableSelect }) {
    const t = useTranslate()
    if(!source) return;
    const [page, setPage] = React.useState(1)
    const [selected, setSelected] = React.useState([])
    const data = source.slice(page*10-10, 10*page)
    const { onOpen, onClose, isOpen } = useDisclosure()
    React.useEffect(() => {
        setSelected([])
    }, [page])
    return (<>
        {buttons && buttons.length > 0 && <div className={'flex space-x-2 my-2'}>
            {buttons.map(b => (<div onClick={async() => {
                await b.click(selected);
                setSelected([])
            }} className={`${(selected.length > 0 || !b.action) ? `${b.danger ? 'bg-gray-200 dark:bg-gray-700 text-red-400 hover:bg-red-400 dark:hover:bg-red-500 hover:text-white' : 'bg-gray-200 dark:bg-gray-700 dark:text-white text-gray-800 hover:bg-gray-300 dark:hover:bg-gray-600'} cursor-pointer` : 'bg-gray-100 dark:bg-gray-800 text-gray-500 cursor-not-allowed'} py-2 px-3 rounded-lg text-center flex space-x-1.5`}>
                <p className={'text-xl mt-0.5'}>
                    {b.icon}
                </p>
                <p className={`text-md font-semibold`}>{b.label}</p>
            </div>))}
        </div>}
        {/*<div className={`bg-gray-50 dark:bg-gray-800 px-3 py-1 flex font-semibold ${className}`}>
            {/*<p className={'justify-start cursor-pointer'}>
                Фильтр
            </p>
            <Dropdown items={dropdown} disabled={selected.length == 0}>
                <p className={`flex space-x-3 ${selected.length == 0 ? 'text-gray-500 cursor-not-allowed' : 'cursor-pointer'}`}>
                    Действия <BsThreeDots className={'ms-2 mt-1'} />
                </p>
            </Dropdown>
            <p className={'ml-auto justify-end'}>
                {t('app.ui.table.count', { count: source.length })}
            </p>
        </div>*/}
        <table className={`w-full bc-table`}>
            <tr className={'bg-gray-200 dark:bg-gray-800'}>
                {!disableSelect && <th className={'ps-3 pb-1 pt-2'}>

                </th>}
                {columns.map(column => (
                    <th className={'px-4 pb-1 pt-2'}>
                        {column.label}
                    </th>
                ))}
            </tr>
            {data.map((s, index) => (
                <tr className={`${index % 2 === 0 ? 'bg-gray-100 dark:bg-gray-700' : 'bg-gray-200 dark:bg-gray-800'} hover:bg-gray-300 dark:hover:bg-gray-600 cursor-pointer`} onClick={(e) => {
                    if(e.detail == 1) {
                        if(!disableSelect) {
                            if (!selected.find(e => e.index == index)) setSelected([...selected, {index: index, ...s}])
                            else setSelected([...selected.filter(e => e.index != index)])
                        } else if(double) {
                            double(s)
                        }
                    } else if (e.detail == 2) {
                        if(double) double(s)
                    }
                }}>
                    {!disableSelect && <td className={'ps-3 py-2'}>
                        <Checkbox checked={selected.find(e => e.index == index)} onChange={(e) => {
                            if(e.target.checked) setSelected([...selected, { index: index, ...s }])
                            else setSelected([...selected.filter(e => e.index != index)])
                        }} />
                    </td>}
                    {columns.map(c => {
                        return (<td className={'px-4 py-2'}>
                            {c.display ? c.display(s[c.value], s) : s[c.value]}
                        </td>)
                    })}
                </tr>
            ))}
        </table>
        {data.length == 0 && <div className={'bg-gray-50 dark:bg-gray-800 w-full py-10'}>
            <p className={'text-4xl text-gray-500 flex justify-center'}>
                <ImFileEmpty />
            </p>
            <p className={'font-bold text-2xl mt-2 text-center'}>
                Ничего не найдено
            </p>
        </div>}
        {source.length > 10 && <Pagination className={'my-3 text-end'} current={page} showSizeChanger={false} onChange={(e) => setPage(e)} total={source.length} />}
    </>)
}