import {
    Box,
    GridItem,
    Heading,
    Menu,
    MenuButton, MenuItem,
    MenuList,
    SimpleGrid,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import {IoIosArrowDown} from "react-icons/io";
import Skeleton from "../../skeleton/cards";
import React from "react";
import {useTranslate} from "@tolgee/react";

export default function OperatingSystem({ groups, selected, setSelected, ram }) {
    const t = useTranslate()
    return (
        <SimpleGrid columns={12} spacing={8}>
            {groups.filter(e => e.os.length > 0).map(g => (
                <GridItem colSpan={{ base: 12, md: 4, "2xl": 3 }}>
                    <Box w={'100%'} bg={useColorModeValue(selected.groupId == g.id ? 'blackAlpha.100' : 'blackAlpha.200', 'gray.700')} rounded={'lg'} p={4} border={'1.5px solid'} cursor={g.minRam > ram ? 'not-allowed' : 'pointer'} borderColor={selected.groupId == g.id ? 'blue.300' : 'transparent'} onClick={() => {
                        if(selected.groupId != g.id && g.minRam <= ram) setSelected(g.os[0])
                    }} _hover={selected.groupId != g.id && { bg: useColorModeValue('blackAlpha.100', 'gray.700'), boxShadow: 'sm' }}>
                        <img src={`/os/${g.slug}.svg`} />
                        <Heading size={'md'} fontWeight={600} mt={4} mb={1}>{g.name}</Heading>
                        <Menu disabled={g.minRam > ram} isLazy>
                            <MenuButton>
                                <Text fontWeight={600} color={useColorModeValue('blackAlpha.600', 'whiteAlpha.600')} display={'flex'}>{g.name} {selected.groupId == g.id ? selected.name : g.os[0].name} <IoIosArrowDown style={{ marginLeft: 5, marginTop: 5 }} /></Text>
                            </MenuButton>
                            <MenuList>
                                {g.os.map(o => <MenuItem isDisabled={g.minRam > ram} onClick={() => { if(g.minRam <= ram) setSelected(o) }}>{t('app.services.version')} {o.name}</MenuItem>)}
                            </MenuList>
                        </Menu>
                    </Box>
                </GridItem>
            ))}
        </SimpleGrid>
    )
}