import Cookies from "js-cookie";
import Router from "next/router";
import React from "react";
import Link from 'next/link'
import {
    IconButton,
    useDisclosure,
    Modal,
    Button,
    Tag,
    Drawer,
    Input,
    DrawerContent,
    Avatar,
    ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton
} from '@chakra-ui/react'
import {
    FiChevronDown, FiServer, FiCloud, FiSettings, FiLifeBuoy, FiUsers, FiMail, FiMenu, FiPlus
} from 'react-icons/fi';
import { BiMessageDetail, BiTransferAlt } from 'react-icons/bi'
import {FaLanguage, FaTasks} from 'react-icons/fa'
import { AiOutlineUnorderedList, AiOutlineTags, AiOutlineFileDone } from 'react-icons/ai'
import { TbFileInvoice, TbUsers } from 'react-icons/tb'
import {
    MdOutlineAutoFixHigh,
    MdOutlineAttachMoney,
    MdOutlineMoneyOff,
    MdOutlineCampaign,
} from 'react-icons/md'
import {HiKey, HiOutlineDocumentReport} from 'react-icons/hi'
import {BsFillCreditCardFill} from 'react-icons/bs'
import {TbReportMoney} from 'react-icons/tb'
import { GiReceiveMoney } from 'react-icons/gi'
import { RiAdvertisementLine, RiFileTransferLine } from 'react-icons/ri'
import axios from 'axios'
import {useTranslate} from "@tolgee/react";
import Dropdown from '/components/ui/dropdown'
import { Form } from 'antd'
import Api from "../Api";

const Categories = [
    {
        name: 'app.services', 
        items: [
            { name: 'products.cloud_servers', icon: <FiCloud />, href: '/cloud-servers' },
            { name: 'products.dedicated_servers', icon: <FiServer />, href: '/dedicated-servers' },
        ]
    },
    {
        name: 'app.navigation.profile', 
        items: [
            { name: 'app.settings', icon: <FiSettings />, href: '/settings' },
            { name: 'app.support', icon: <FiLifeBuoy />, href: '/support' },
        ]
    },
    {
        name: 'app.navigation.finances', 
        items: [
            { name: 'app.navigation.payments', icon: <MdOutlineAttachMoney />, href: '/finances/payments' },
        ]
    },
]

const CategoriesAdmin = [
    {
        name: 'app.support',
        items: [
            { name: 'app.admin.support', icon: <FiLifeBuoy />, href: '/admin/support' },
            { name: 'app.admin.advertisements', icon: <RiAdvertisementLine />, href: '/admin/support/advertisements' },
        ]
    },
    {
        name: 'app.services',
        items: [
            { name: 'app.navigation.list', icon: <AiOutlineUnorderedList />, href: '/admin/services' },
        ]
    },
    {
        name: 'app.definitions.users',
        items: [
            { name: 'app.admin.users', icon: <FiUsers />, href: '/admin/users' },
            { name: 'app.navigation.contracts', icon: <AiOutlineFileDone />, href: '/admin/contracts' },
        ]
    },
    {
        name: 'app.navigation.company',
        items: [
            { name: 'app.navigation.companies', icon: <AiOutlineTags />, href: '/admin/settings/companies' },
            { name: 'app.navigation.reports', icon: <HiOutlineDocumentReport />, href: '/admin/settings/reports' },
        ]
    },
    {
        name: 'app.settings',
        items: [
            { name: 'app.global_settings', icon: <FiSettings />, href: '/admin/settings' },
            { name: 'app.navigation.languages', icon: <FaLanguage />, href: '/admin/settings/languages' },
            { name: 'app.navigation.messagetemplates', icon: <BiMessageDetail />, href: '/admin/settings/templates/messages' },
            { name: 'app.navigation.contracttemplates', icon: <AiOutlineFileDone />, href: '/admin/settings/templates/contracts' },
        ]
    },
    {
        name: 'app.navigation.tools',
        items: [
            { name: 'app.service_transfer', icon: <BiTransferAlt />, href: '/admin/tools/transfer/service' },
            { name: 'app.payment_transfer', icon: <RiFileTransferLine />, href: '/admin/tools/transfer/payment' },
            { name: 'app.navigation.tasks', icon: <FaTasks />, href: '/admin/tools/tasks' },
            { name: 'app.navigation.price_fix', icon: <MdOutlineAutoFixHigh />, href: '/admin/tools/fixprice' }
        ]
    },
    {
        name: 'app.navigation.finances',
        items: [
            { name: 'app.navigation.payments', icon: <MdOutlineAttachMoney />, href: '/admin/finances/payments' },
            { name: 'app.navigation.expenses', icon: <MdOutlineMoneyOff />, href: '/admin/finances/expenses' },
            { name: 'app.admin.stats', icon: <TbFileInvoice />, href: '/admin/finances/stats' },
        ]
    },
    {
        name: 'app.navigation.products',
        items: [
            { name: 'app.navigation.tariffs', icon: <GiReceiveMoney />, href: '/admin/payments' },
            { name: 'app.navigation.product_types', icon: <TbReportMoney />, href: '/admin/expenses' },
        ]
    },
    {
        name: 'app.navigation.marketing',
        items: [
            { name: 'app.navigation.campaigns', icon: <MdOutlineCampaign />, href: '/admin/marketing/campaigns' },
            { name: 'app.navigation.mailing', icon: <FiMail />, href: '/admin/marketing/mailing' },
            { name: 'app.navigation.referal', icon: <TbUsers />, href: '/admin/marketing/referal' }
        ]
    },
]

export default function Navbar({ children, user, router, setUser, nav, displayMoney, setBalanceAdd }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCreateProjectOpen, setIsCreateProjectOpen] = React.useState(false)
    const t = useTranslate()
    const [loading, setLoading] = React.useState(false)
    let count = 0;
//    let count = support ? support.filter(e => e.messages.filter(d => d.unread == 1 && d.isadmin == 1).length > 0).length : 0;
    const wtNav = ['/login', '/register', '/registered', '/restore', '/backendError', '/dedicated-servers/1/create/[id]'];
    const [createProjectForm] = Form.useForm()

    return (
        <div className={'min-h-screen'}>
          {!wtNav.includes(router.route) && <>
              <SidebarContent count={count} router={router} t={t} user={user} setIsCreateProjectOpen={setIsCreateProjectOpen} onClose={onClose} className={'hidden bg-gray-100 dark:bg-gray-800'} />
              <div id={'mobile-nav-drawer'}>
                  <Drawer autoFocus={false} isOpen={isOpen} placement="left" onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose} size="full">
                      <DrawerContent>
                          <SidebarContent count={count} router={router} t={t} user={user} onClose={onClose} setIsCreateProjectOpen={setIsCreateProjectOpen} />
                      </DrawerContent>
                  </Drawer>
              </div>
              <div className={'ml-0 md:ml-14 py-4 md:py-0 pr-4 items-center flex md:justify-end'} justifyContent={{ base: 'space-between', md: 'flex-end' }}>
                  <div className={'mr-10 justify-start'}>
                      {nav}
                  </div>
                  <div className={'mr-auto'}>
                      <IconButton display={{ base: 'flex', md: 'none' }} onClick={onOpen} variant="outline" aria-label="open menu" icon={<FiMenu />} />
                  </div>
                  <div className={'flex md:hidden mr-auto'}>
                      <img className={'hidden dark:block'} src={'/logoGray.svg'} style={{ width: 32 }} />
                      <img className={'block dark:hidden'} src={'/logoBlue.svg'} style={{ width: 32 }} />
                  </div>
                  <div className={'flex'}>
                      <div className={'flex items-center'}>
                          {user.groupId != 'user' && <Link href={'/admin'}>
                              <div className={'cursor-pointer dark:text-gray-400 text-gray-700 hover:bg-gray-100 hover:bg-gray-700 rounded-full p-2'}>
                                  <HiKey className={'text-3xl'} />
                              </div>
                          </Link>}
                          <div className={'hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg hidden lg:block mx-5 py-2 my-1 px-3 cursor-pointer'} onClick={() => setBalanceAdd(true)}>
                          <div className={'inline-flex items-center space-x-4'}>
                              <p className={'text-lg text-gray-600 dark:text-neutral-300'}><BsFillCreditCardFill /></p>
                              <div className={'inline-flex flex-col'}>
                                  <p className={'text-sm text-gray-600 dark:text-neutral-300'}>{t('app.balance')}</p>
                                  <p className={`text-sm font-bold ${user.balance < 0 && 'text-red-400'}`} color={user.balance < 0 && 'red.400'}>
                                      {displayMoney(user.balance, null, true, true)}
                                  </p>
                              </div>
                          </div>
                      </div>
                          <Dropdown items={[
                            {
                                label: (<>{t('app.add_balance')}</>),
                                click: () => {
                                    setBalanceAdd(true)
                                }
                            },
                            {
                                label: (<>{t('app.settings')}</>),
                                click: () => {
                                    Router.push('/settings')
                                }
                            },
                            {
                                label: (<>{t('app.navigation.payments')}</>),
                                click: () => {
                                    Router.push('/finances/payments')
                                }
                            },
                            {
                                label: (
                                    <p>
                                        {t('app.navigation.exit')}
                                    </p>
                                ),
                                click: async() => {
                                    Api.delete(`/user/sessions/${await Cookies.get('bc_token')}`);
                                    Cookies.remove('bc_token'); Router.push('/login');
                                },
                                danger: true,
                            },
                          ]}>
                            <div className={'inline-flex space-x-3 items-center hover:bg-gray-100 dark:hover:bg-gray-700 px-2 py-1 rounded-lg cursor-pointer'}>
                                <Avatar size={'sm'} />
                                <div className={'inline-flex flex-col'}>
                                    <p className={'text-sm'}>{user.name}</p>
                                    <p className={'text-xs text-gray-500 dark:text-neutral-300'}>
                                        <p className={'hidden lg:block'}>{user.email}</p>
                                        <p className={'block lg:hidden'}>{t('app.balance')}: {displayMoney(user.balance, null, true, true)}</p>
                                    </p>
                                </div>
                                <FiChevronDown />
                            </div>
                          </Dropdown>
                      </div>
                  </div>
              </div>
          </>}
          <div className={`${!wtNav.includes(router.route) && 'ml-0 md:ml-60'}`}>
            {children}
          </div>
            <Modal isOpen={isCreateProjectOpen} onClose={() => setIsCreateProjectOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {t('app.projects.create')}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Form onFinish={(e) => {
                            setLoading(true);
                            Api.post('/user/projects', e).then(({data}) => {
                                setLoading(false);
                                setIsCreateProjectOpen(false);
                                setUser({...user, projects: [...user.projects, data.project] })
                                createProjectForm.resetFields()
                            })
                        }}>
                            <Form.Item className={'mb-4'} name={'name'}>
                                <Input placeholder={t('app.projects.creating.name')} />
                            </Form.Item>
                            <Form.Item className={'mb-3'}>
                                <Button isLoading={loading} type={'submit'}>{t('app.actions.create')}</Button>
                            </Form.Item>
                        </Form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}

const SidebarContent = ({ t, onClose, router, count, className, user, setIsCreateProjectOpen }) => {
    return (
        <div className={`w-full ${className} md:block md:w-60 fixed h-full overflow-y-auto`}>
            <Link href={'/dashboard'} onClick={onClose}>
                <div className={'flex items-center justify-between mb-10 mt-5 mx-5'}>
                    <img className={'block dark:hidden'} src={'/logoNav.svg'} style={{ cursor: 'pointer' }} />
                    <img className={'hidden dark:block'} src={'/logoNavDark.svg'} style={{ cursor: 'pointer' }} />
                </div>
            </Link>
            <div>
                {router.pathname.split('/')[1] != 'admin' && <div>
                    {user && user.projects.map(project => (
                        <NavItem style={{ overflowX: 'hidden' }} key={""} onClose={onClose} count={count} selected={router.query.id == project.id && true} href={`/projects/${project.id}`}>
                            <img src={'/images/default_project.png'} className={'h-5 me-2'} />
                            <p style={{ fontSize: '0.97rem' }}>{project.name ? project.name : t('app.projects.default_name')}</p>
                        </NavItem>
                    ))}
                    <div className={`hover:text-black dark:hover:text-white mx-2 my-0.5 rounded-md font-medium hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer`} onClick={() => {
                        setIsCreateProjectOpen(true)
                        onClose()
                    }}>
                        <div className={'flex p-1.5 mx-2 items-center'}>
                            <p className={'me-3'}><FiPlus /></p>
                            <p style={{ fontSize: '0.97rem' }}>{t('app.projects.create')}</p>
                        </div>
                    </div>
                </div>}
                {(router.pathname.split('/')[1] == 'admin' ? CategoriesAdmin : Categories).map((c) => {
                    let items = c.items.map((i, index) => {
                        return (<NavItem key={index} onClose={onClose} count={count} href={i.href} selected={i.href.split('/')[1] == 'admin' ? i.href == router.pathname : i.href.includes('finances') ? i.href == router.pathname : i.href.split('/')[1] == router.pathname.split('/')[1]}>
                            <p className={'me-3'}>{i.icon}</p>
                            <p style={{ fontSize: '0.97rem' }}>{t(i.name)}</p>
                        </NavItem>)
                    })
                    return (<>
                        <p className={'mt-4 mb-2 mx-6 font-semibold dark:text-gray-400 text-gray-500'}>{t(c.name)}</p>
                        {items}
                    </>)
                })}
            </div>
        </div>
    );
};

const NavItem = ({ icon, children, href, selected, count, onClose, ...rest }) => {
    return (
        <Link href={href} className={'hover:text-black dark:hover:text-white'}>
            <div className={`mx-2 my-0.5 rounded-md ${selected && 'dark:bg-gray-700 bg-gray-200'} font-medium hover:bg-gray-200 dark:hover:bg-gray-700`} onClick={onClose} {...rest}>
                <div className={'flex p-1.5 mx-2 items-center'}>
                    {children}
                    {href == '/support' && count > 0 && <Tag rounded={'full'} ml={2} mt={0.5} size={'sm'} colorScheme={'red'}>{count}</Tag>}
                </div>
            </div>
        </Link>
    );
};